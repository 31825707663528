import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/theme-twentytwenty/src/components/CallToAction/CallToAction';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Wrapper width={1024} mdxType="Wrapper">
      <h1>{`Service`}</h1>
      <h2 {...{
        "id": "umarbeitung-von-schmuck"
      }}>{`Umarbeitung von Schmuck`}</h2>
      <p>{`  Haben Sie Schmuck geerbt oder Schmuckstücke die nicht mehr Ihren Stil entsprechen?
Kommen Sie zu uns!
Durch eine Schmuck-Umarbeitung wird aus diesen alten Schätzen neuer, attraktiver Schmuck.
Reparaturen, Größenänderungen oder Aufarbeitung kann den ideellen Wert des Schmuckes erhalten oder hervorheben.
Ihre Schmuckstücke können ebenfalls durch einfassen Ihrer vorhandenen Edelsteine oder neuen Brillanten zum neuen Glanz erstrahlen.
Neue Unikate entstehen durch einschmelzen Ihres Goldes, zu dem wir auch passende Sets anfertigen können.
Aus einem Trauring wird zum Beispiel ein schöner Anhänger.
Die Goldschmiedevielfalt ist unendlich...
Kommen Sie am Besten mit Ihren Schmuckstücken bei uns vorbei.
Wir beraten Sie gerne, welche Möglichkeiten sich bieten.`}</p>
      <h2 {...{
        "id": "lasertechnik"
      }}>{`Lasertechnik`}</h2>
      <p>{`  Ein Teil unserer Kollektion wie Ringe , Anhänger und Kettenverschlüsse, wird mit Lasertechnik hergestellt.
Liebe zum Detail , außergewöhnliches Design und absolute Präzision lassen diese Schmuckstücke einzigartig werden.
Der Einsatz von Lasertechnik ermöglicht es punktgenau zu arbeiten, dabei wird das Edelmetall schonend verbunden.
Dadurch ist sogar das Arbeiten in Steinnähe möglich.
Mit modernster Technik und dem nötigen Fachwissen bietet Ihnen unser Haus unvergleichbare Unikate,
z.B. Gitterschmuck aus Platin und Gold mit Edelsteinen oder Perlen.`}</p>
    </Wrapper>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      